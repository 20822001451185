
function matomoTagManager() {

  <!-- Matomo Tag Manager -->
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  g.async = true;
  g.src = 'https://matomo.tradebyte.org/js/container_bExd6Umu.js';
  s.parentNode.insertBefore(g, s);
  <!-- End Matomo Tag Manager -->

}
