function runMatomo(account_ID, username) {

  /*
  For customDimension: userType, Matomo is set to accept values "Customer" or Masterlogin" -> var userType.
  var userType is set depend on providedUserType parameter passed from /catalog API response under attribute: masterLogin.
  masterLogin is either empty for customer type of user or contain loginName if user logged in via master login.

  for example:
  masterLogin: ""
  or
  masterLogin: "jsmith"
  */

  // set userType
  var userType = "Customer";
  if(username.includes("Administrator") || username.includes("TBUserCopy")){
    userType = "Masterlogin";
  }
  <!-- Matomo -->
  var _paq = window._paq = window._paq || [];
  _paq.push(['setCustomDimension',
    customDimensionId = 1,
    customDimensionValue = account_ID]);
  _paq.push(['setCustomDimension',
    customDimensionId = 2, customDimensionValue = userType]);
  /\* tracker methods like "setCustomDimension" should be called before "trackPageView" \*/
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = "//matomo.tradebyte.org/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '5']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();
  <!-- End Matomo Code -->

  matomoScriptStatusUpdate(account_ID,userType)
}

function matomoScriptStatusUpdate(account_ID, userType){
  // matomo status update
  document.getElementById("matomoStatus").innerHTML = '[Matomo]:  '+
    'BrandId: ' + account_ID + ", " +
    'with UserType: ' + userType;
}
